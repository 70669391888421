* {
  box-sizing: border-box;
}

body {
  margin: 0;
}

/* .container {
  margin: 1rem;
  text-align: center;
} */

.header-tab {
  width: 100%;
  height: 50px;
  background-color: black;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
}

.nav {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 2rem;
  padding: 0 1rem;
  position: -webkit-sticky;
  background-color: white;
  position: sticky;
  top: 0; /* Stick to the top of the viewport */
  z-index: 1000; /* Ensure it stays above other content */
}

.site-title {
  font-size: 2rem;
}

.nav ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  gap: 1rem;
}

.nav a {
  color: inherit;
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
  padding: .25rem;
  position: relative;
}

.nav a::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 3px;
  left: 0;
  bottom: 0rem;
  background-color: transparent;
  transition: background-color 0.3s ease-in-out;
}

.nav li.active a {
  font-weight: bold;
}

.nav li.active a::after {
  background-color: #000;
}

.nav li:hover a::after {
  background-color: #000;
}